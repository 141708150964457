exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biznes-consultirovanie-js": () => import("./../../../src/pages/biznes-consultirovanie.js" /* webpackChunkName: "component---src-pages-biznes-consultirovanie-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-slider-js": () => import("./../../../src/pages/components/slider.js" /* webpackChunkName: "component---src-pages-components-slider-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-economicheskaya-bezopasnpst-js": () => import("./../../../src/pages/economicheskaya-bezopasnpst.js" /* webpackChunkName: "component---src-pages-economicheskaya-bezopasnpst-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maetalloramka-js": () => import("./../../../src/pages/maetalloramka.js" /* webpackChunkName: "component---src-pages-maetalloramka-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-yuridicheskaya-podderjka-js": () => import("./../../../src/pages/yuridicheskaya-podderjka.js" /* webpackChunkName: "component---src-pages-yuridicheskaya-podderjka-js" */),
  "component---src-pages-yuridicheskaya-pomosh-js": () => import("./../../../src/pages/yuridicheskaya-pomosh.js" /* webpackChunkName: "component---src-pages-yuridicheskaya-pomosh-js" */)
}

